import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import Sectiontitle from "../section-title";
import bride1 from "../../images/groomsmen-bridesmaid/1.jpg";
import bride2 from "../../images/groomsmen-bridesmaid/2.jpg";
import bride3 from "../../images/groomsmen-bridesmaid/3.jpg";
import bride4 from "../../images/groomsmen-bridesmaid/4.jpg";
import bride5 from "../../images/groomsmen-bridesmaid/5.jpg";
import bride6 from "../../images/groomsmen-bridesmaid/6.jpg";

import "./style.css";

const People = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div id="people" className="person-area section-padding pb-70">
      <div className="container">
        <Sectiontitle section={"Шаферки & Шафери"} />
        <div className="person-area-menu">
          {/* <div className="person-btn">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Кумове
                </NavLink>
              </NavItem>
              <NavItem></NavItem>
            </Nav>
          </div> */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="Groomsman-wrap">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride1} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Боряна Славчева</h3>
                        <span>Partner in crime</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride2} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Десислава Йорданова</h3>
                        <span>Интелигенцията на Северозапада</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride3} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Ива Мотовска</h3>
                        <span>Будната съвест</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride4} alt="" />
                      </div>
                      <div className="person-content">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                          {/* <div className="person-wrap">
                            <div className="person-img">
                              <img src={bride6} alt="" />
                              <div className="social-list">
                                <ul>
                                  <li>
                                    <Link to="/">
                                      <i className="fa fa-facebook-f"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/">
                                      <i className="fa fa-twitter"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="/">
                                      <i className="fa fa-skype"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="person-content">
                              <h3>Иван Стефанов</h3>
                              <span>Sister</span>
                            </div>
                          </div> */}
                        </div>
                        <div className="person-content">
                        <h3>Иван Стефанов</h3>
                        <span>Най-добрият приятел</span>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride5} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Георги Даракчиев</h3>
                        <span>Вечният оптимист</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="person-wrap">
                      <div className="person-img">
                        <img src={bride6} alt="" />
                      </div>
                      <div className="person-content">
                        <h3>Антон Антонов</h3>
                        <span>Дясната ръка</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
};

export default People;
