import React from "react";
import { Link } from "react-router-dom";
import couple1 from "../../images/couple/1.jpg";
import couple2 from "../../images/couple/2.jpg";
import couple3 from "../../images/couple/3.jpg";
import "./style.css";

const Couple2 = () => {
  return (
    <div id="couple" className="couple-area section-padding pb-70">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Семейство Арсови</h2>
          </div>
        </div>
        <div className="couple-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 couple-single">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={couple1} alt="" />
                </div>
                <div className="couple-text">
                  <div className="couple-content">
                    <h2>Наталия</h2>
                    <p className="couple-content-desc">
                      Здравейте, приятели! <br /> Всички знаете за моята склонност да
                      се боря с престъпността и да участвам в разкриване на
                      престъпления, но един човек успя да ОТКРАДНЕ сърцето ми,
                      да УБИЕ всяко лошо настроение в мен и да ме ОТВЛЕЧЕ за цял
                      живот! Всичко това, естествено, беше напълно доброволно и
                      желано, ноооо както знаете за всяко нещо трябват
                      свидетели! Най-сърдечно ви каня да присъствате на моето
                      най-искрено и истинско “ДА” в живота!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 couple-single md-0">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={couple2} alt="" />
                </div>
                <div className="couple-text">
                  <div className="couple-content">
                    <h2>Лъчезар</h2>
                    <p className="couple-content-desc">
                      ТИ, игра ли футбол тази седмица? <br /> Всички ме
                      познавате много добре, може би за вас съм добър приятел,
                      отдаден на каузи човек, обичащ чичо или просто “онзи на
                      Нати”. Ще се радвам да сте с мен, когато встъпвам в нова
                      за мен роля - тази на любящ съпруг и глава на семейство!
                      Очаквам ви в Утопия, където официално ще бъда НАСТЪПАН от
                      най-хубавото нещо, което ми се е случвало - моята
                      половинка Нати!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Couple2;
