import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import doner from "../../images/gift/2.png";
import chocolate from "../../images/gift/Chocolate.png";
import cyberSecurity from "../../images/gift/Cyber-Security.png";
import fcTornado from "../../images/gift/FCTornado2013Logo.png";
import futbolWithPurpose from "../../images/gift/logo-football-effects_small.png";
import thor from "../../images/gift/Thor.png";
import airplane from "../../images/gift/airplane.png";
import aunt from "../../images/gift/aunt.png";
import concert from "../../images/gift/concert.png";
import dna from "../../images/gift/hand-drawn-genes-and-dna-illustration-png.png";
import movies from "../../images/gift/movies.png";
import photo from "../../images/gift/photo.png";
import scienceBreaker from "../../images/gift/science-BEAKER.png";
import starWars from "../../images/gift/star-wars.png";
import travelbag from "../../images/gift/travelbag.png";
import uncle from "../../images/gift/uncle.png";
import "./style.css";

class Gift extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1500,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div id="gift" className="gift-area">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <h2>Без цветя, но с кауза</h2>
              <p>
                Скъпи гости, <br /> <br /> 
                за да няма излишно затруднение <br /> 
                ето нашето предложение: <br /><br /> 
                за подаръци не се чудете, <br />
                поздравите в пликче ни пуснете!<br /><br /> 
                Цветя и рози също не търсете,<br />
                красотата им е преходна, не я мислете<br /><br /> 
                по-добре направо нашата кауза изберете, <br />
                на детенце в нужда помогнете!
              </p>
            </div>
          </div>
          <div className="row gift-item">
            <div className="col-lg-12">
              <Slider {...settings}>
                <div className="item">
                  <img src={movies} alt="" />
                </div>
                <div className="item">
                  <img src={photo} alt="" />
                </div>
                <div className="item">
                  <img src={scienceBreaker} alt="" />
                </div>
                <div className="item mt-1">
                  <img src={starWars} alt="" />
                </div>
                <div className="item">
                  <img src={travelbag} alt="" />
                </div>
                <div className="item">
                  <img src={uncle} alt="" />
                </div>
                <div className="item">
                  <img src={dna} alt="" />
                </div>
                <div className="item">
                  <img src={concert} alt="" />
                </div>
                <div className="item">
                  <img src={aunt} alt="" />
                </div>
                <div className="item mt-1">
                  <img src={airplane} alt="" />
                </div>
                <div className="item">
                  <img src={thor} alt="" />
                </div>
                <div className="item">
                  <img src={doner} alt="" />
                </div>
                <div className="item">
                  <img src={chocolate} alt="" />
                </div>
                <div className="item mt-2">
                  <img src={cyberSecurity} alt="" />
                </div>
                <div className="item">
                  <img src={fcTornado} alt="" />
                </div>
                <div className="item mt-3">
                  <img src={futbolWithPurpose} alt="" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gift;
