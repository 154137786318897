import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import "./style.css";

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  render() {
    const { isMenuShow } = this.state;

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
          {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}
          <div className="logo2">
            <h2>
              <Link to="/home">Наталия & Лъчезар</Link>
            </h2>
          </div>
          <ul onClick={this.menuHandler} className="responsivemenu">
            <li>
              <AnchorLink href="#home">Начало</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#couple">За нас</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#bestmen">Кумове</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#story">Нашата история</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#people">Шафери</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#event">Програма</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#rsvp">Анкета</AnchorLink>
            </li>
            <li>
              <AnchorLink href="#gift">Кауза</AnchorLink>
            </li>
            {/* <li><Link to='/blog'>Blog</Link></li>
                        <li><Link to='/Blog-details'>Blog Details</Link></li> */}
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}
