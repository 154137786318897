import React from "react";
import { Link } from "react-router-dom";
import Sectiontitle from "../section-title";
import strory1 from "../../images/events/1.jpg";
import strory2 from "../../images/events/2.jpg";
import strory3 from "../../images/events/3.jpg";
import strory4 from "../../images/events/4.jpg";
import strory5 from "../../images/events/5.jpg";

import "./style.css";

const Location = () => {
  return (
    <div id="event" className="service-area section-padding">
      <div className="container">
        <Sectiontitle section={"Кога & Къде"} />
        <div className="service-area-menu">
        <div className="Ceremony-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className="ceromony-content ceromony-content2">
                <h3>Вземане на булката</h3>
                <span>Събота, 09 Септември 23, 14:30-15:30</span>
                <span>Хотел Утопия Форест</span>
                <a href="https://goo.gl/maps/yeHJwokRnVTU82KL8" target="blank">
                  Локация
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="ceromony-img">
                <img src={strory5} alt="" />
              </div>
            </div>
          </div>
        </div>
          <div className="Ceremony-wrap">
            <div className="row">
              <div className="col-lg-5">
                <div className="ceromony-img">
                  <img src={strory1} alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="ceromony-content">
                  <h3>Изнесен ритуал</h3>
                  <span>Събота, 09 Септември 23, 16:00-16:30</span>
                  <span>Градината на Утопия Форест</span>
                  {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p> */}
                  <a
                    href="https://goo.gl/maps/yeHJwokRnVTU82KL8"
                    target="blank"
                  >
                    Локация
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Ceremony-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className="ceromony-content ceromony-content2">
                <h3>Welcome drink</h3>
                <span>Събота, 09 Септември 23, 16:30-17:30</span>
                <span>Градината на Утопия Форест</span>
                {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p> */}
                <a href="https://goo.gl/maps/yeHJwokRnVTU82KL8" target="blank">
                  Локация
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="ceromony-img">
                <img src={strory3} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="Ceremony-wrap">
          <div className="row">
            <div className="col-lg-5">
              <div className="ceromony-img">
                <img src={strory2} alt="" />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="ceromony-content">
                <h3>Ресторант</h3>
                <span>Събота, 09 Септември 23, 19:00-23:00</span>
                <span>Открит ресторант Утопия Форест</span>
                {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p> */}
                <a href="https://goo.gl/maps/yeHJwokRnVTU82KL8" target="blank">
                  Локация
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Ceremony-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className="ceromony-content ceromony-content2">
                <h3>After Party</h3>
                <span>Събота, 09 Септември 23, 23:00-до зори</span>
                <span>Бар Утопия Форест</span>
                {/* <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal </p> */}
                <a href="https://goo.gl/maps/yeHJwokRnVTU82KL8" target="blank">
                  Локация
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="ceromony-img">
                <img src={strory4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
