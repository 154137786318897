import React, { Children, Component } from "react";
import "./style.css";
import ElementWrapper from "./elementWrapper";
import AdditionalGuest from "./additionalGuest";
import WithChildren from "./withChildren";
import ChildrenData from "./childrenData";
import Swal from "sweetalert2";

const initialState = {
  name: "",
  email: "",
  willAttend: "",
  error: {},
  additinalGuest: "",
  hasAdditionalGuest: "",
  howManyChildren: "-1",
  firstChildName: "",
  secondChildName: "",
  thirdChildName: "",
  accomodationHelp: "",
  menu: "",
  alcohol: "",
  partnerMenu: "",
  partnerAlcohol: "",
  roomInfo: "",
  childrenInfo: "",
  firstChildMenu: "",
  secondChildMenu: "",
  thirdChildMenu: "",
  paperInvitation: "",
};

class Rsvp extends Component {
  state = initialState;

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = "";

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  sendEmail() {
    const self = this;

    Swal.fire({
      title: "Сигурни ли сте?",
      icon: 'question',
      confirmButtonText: "Да!",
      cancelButtonText: "Не, искам да променя отговора си.",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        self.setState(initialState);
        Swal.fire(
          "Няма смисъл вече",
          "Съжаляваме, но сватбата свърши и няма смисъл да се записвате.",
          "success"
        );
      }
    });
  }

  subimtHandler = (e) => {
    e.preventDefault();

    const {
      name,
      email,
      willAttend,
      error,
      additiоnalGuest,
      hasAdditionalGuest,
      howManyChildren,
      firstChildName,
      secondChildName,
      thirdChildName,
      accomodationHelp,
      menu,
      alcohol,
      partnerMenu,
      partnerAlcohol,
      firstChildMenu,
      secondChildMenu,
      thirdChildMenu,
    } = this.state;

    Object.keys(error).forEach((key) => (error[key] = ""));

    this.setState((prevState) => ({
      ...prevState,
      error: {},
    }));

    if (willAttend === "Не" && name !== "" && email !== "") {
      this.sendEmail();
      return;
    }

    if (name === "") {
      error.name = "Моля, въведете име и фамилия";
    }
    if (email === "") {
      error.email = "Моля, въведете валиден имейл";
    }
    if (willAttend === "") {
      error.willAttend = "Моля, кажете дали ще присъствате на сватбата";
    }
    if (hasAdditionalGuest === "") {
      error.hasAdditionalGuest = "Моля, изберете дали ще бъдете с половинка";
    }
    if (
      hasAdditionalGuest === "Да" &&
      (additiоnalGuest === "" || !additiоnalGuest)
    ) {
      error.additiоnalGuest = "Моля, въвдете име и фамилия на вашата половинка";
    }
    if (accomodationHelp === "") {
      error.accomodationHelp = "Моля, изберете един от предложените варианти";
    }
    if (menu === "") {
      error.menu = "Моля, изберете предпочитано ястие";
    }
    if (alcohol === "") {
      error.alcohol = "Моля, изберете предпочитан алкохол";
    }

    if (hasAdditionalGuest === "Да") {
      if (partnerMenu === "") {
        error.partnerMenu =
          "Моля, изберете предпочитано ястие за вашата половинка";
      }
      if (partnerAlcohol === "") {
        error.partnerAlcohol =
          "Моля, изберете предпочитан алкохол за вашата половинка";
      }
    }

    if (howManyChildren < 0) {
      error.howManyChildren = "Моля, кажете дали ще дойдете с деца";
    }
    if (howManyChildren >= 1 && !firstChildName) {
      error.firstChildName = "Моля, въведете име и фамилия";
    }
    if (howManyChildren >= 2 && !secondChildName) {
      error.secondChildName = "Моля, въведете име и фамилия";
    }
    if (howManyChildren >= 3 && !thirdChildName) {
      error.thirdChildName = "Моля, въведете име и фамилия";
    }

    if (howManyChildren >= 1 && !firstChildMenu) {
      error.firstChildMenu = "Моля, изберете меню за дете 1";
    }
    if (howManyChildren >= 2 && !secondChildMenu) {
      error.secondChildMenu = "Моля, изберете меню за дете 2";
    }
    if (howManyChildren >= 3 && !thirdChildMenu) {
      error.thirdChildMenu = "Моля, изберете меню за дете 3";
    }

    if (error) {
      this.setState({
        error,
      });
    }

    const noErrors = Object.keys(error).every((key) => error[key] === "");

    if (noErrors) {
      this.sendEmail();
    }
  };

  render() {
    const {
      name,
      email,
      error,
      additiоnalGuest,
      hasAdditionalGuest,
      howManyChildren,
      firstChildName,
      secondChildName,
      thirdChildName,
      accomodationHelp,
      menu,
      alcohol,
      partnerMenu,
      partnerAlcohol,
      willAttend,
      firstChildMenu,
      secondChildMenu,
      thirdChildMenu,
      paperInvitation,
    } = this.state;
    return (
      <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="rsvp-wrap">
                <div className="col-12">
                  <div className="section-title section-title4 text-center">
                    <h2>Бъдете наши гости</h2>
                    <p>Моля, попълнете преди 30 Юни 2023г.</p>
                  </div>
                </div>
                <form onSubmit={this.subimtHandler}>
                  <div className="contact-form form-style">
                    <div className="row">
                      <ElementWrapper
                        label="Въведете име и фамилия"
                        isRequired
                        isVisible
                        error={error.name}
                      >
                        <input
                          type="text"
                          value={name}
                          onChange={this.changeHandler}
                          name="name"
                        />
                      </ElementWrapper>

                      <ElementWrapper
                        label="Въведете имейл"
                        isRequired
                        isVisible
                        error={error.email}
                      >
                        <input
                          type="text"
                          onChange={this.changeHandler}
                          value={email}
                          name="email"
                        />
                      </ElementWrapper>

                      <ElementWrapper
                        label="Ще присъствате ли на сватбата?"
                        isRequired
                        isVisible
                        error={error.willAttend}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={willAttend}
                          name="willAttend"
                        >
                          <option disabled value="">
                            Моля изберете
                          </option>
                          <option value="Да">Да</option>
                          <option value="Не">Не</option>
                        </select>
                      </ElementWrapper>

                      <AdditionalGuest
                        willAttend={willAttend}
                        additinalGuest={additiоnalGuest}
                        hasAdditionalGuest={hasAdditionalGuest}
                        error={error}
                        changeHandler={this.changeHandler}
                      ></AdditionalGuest>

                      <WithChildren
                        howManyChildren={howManyChildren}
                        firstChildName={firstChildName}
                        secondChildName={secondChildName}
                        thirdChildName={thirdChildName}
                        error={error}
                        changeHandler={this.changeHandler}
                        isVisible={willAttend === "Да"}
                      ></WithChildren>

                      <ElementWrapper
                        label={
                          !!name
                            ? `Изберете меню за ${name}`
                            : "Изберете меню за вас"
                        }
                        isRequired
                        isVisible={willAttend === "Да"}
                        error={error.menu}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={menu}
                          name="menu"
                        >
                          <option disabled value="">
                            Моля изберете предпочитано основно ястие
                          </option>

                          <option value="Меню Филе от лаврак с пюре от целина">
                            Меню Филе от лаврак с пюре от целина
                          </option>
                          <option value="Меню Пилешки руладини с бекон и пушено сирене">
                            Меню Пилешки руладини с бекон и пушено сирене
                          </option>
                        </select>
                      </ElementWrapper>

                      <ElementWrapper
                        label={
                          !!name
                            ? `Изберете алкохол за ${name}`
                            : "Изберете алкохол за вас"
                        }
                        isRequired
                        isVisible={willAttend === "Да"}
                        error={error.alcohol}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={alcohol}
                          name="alcohol"
                        >
                          <option disabled value="">
                            Моля изберете предпочитан алкохол
                          </option>
                          <option value="Не консумирам алкохол">
                            Не консумирам алкохол
                          </option>
                          <option value="Нямам претенции">
                            Нямам претенции
                          </option>
                          <option value="Ракия">Ракия</option>
                          <option value="Уиски">Уиски</option>
                          <option value="Бяло вино">Бяло вино</option>
                          <option value="Ром">Ром</option>
                        </select>
                      </ElementWrapper>

                      <ElementWrapper
                        label={
                          !!additiоnalGuest
                            ? `Изберете меню за ${additiоnalGuest}`
                            : "Изберете меню за вашата половинка"
                        }
                        isRequired
                        isVisible={
                          willAttend === "Да" && hasAdditionalGuest === "Да"
                        }
                        error={error.partnerMenu}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={partnerMenu}
                          name="partnerMenu"
                        >
                          <option disabled value="">
                            Моля изберете
                          </option>
                          <option value="Меню Филе от лаврак с пюре от целина">
                            Меню Филе от лаврак с пюре от целина
                          </option>
                          <option value="Меню Пилешки руладини с бекон и пушено сирене">
                            Меню Пилешки руладини с бекон и пушено сирене
                          </option>
                        </select>
                      </ElementWrapper>

                      <ElementWrapper
                        label={
                          !!additiоnalGuest
                            ? `Изберете алкохол за ${additiоnalGuest}`
                            : "Изберете алкохол за вашата половинка"
                        }
                        isRequired
                        isVisible={
                          willAttend === "Да" && hasAdditionalGuest === "Да"
                        }
                        error={error.partnerAlcohol}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={partnerAlcohol}
                          name="partnerAlcohol"
                        >
                          <option disabled value="">
                            Моля изберете
                          </option>
                          <option value="Не консумирам алкохол">
                            Не консумирам алкохол
                          </option>
                          <option value="Нямам претенции">
                            Нямам претенции
                          </option>
                          <option value="Ракия">Ракия</option>
                          <option value="Уиски">Уиски</option>
                          <option value="Бяло вино">Бяло вино</option>
                          <option value="Ром">Ром</option>
                        </select>
                      </ElementWrapper>

                      <ChildrenData
                        howManyChildren={howManyChildren}
                        firstChildName={firstChildName}
                        firstChildMenu={firstChildMenu}
                        secondChildName={secondChildName}
                        secondChildMenu={secondChildMenu}
                        thirdChildName={thirdChildName}
                        thirdChildMenu={thirdChildMenu}
                        error={error}
                        changeHandler={this.changeHandler}
                        isVisible={willAttend === "Да" && howManyChildren > 0}
                      ></ChildrenData>

                      <ElementWrapper
                        label="Желаете ли да ви съдействаме с резервация за
                        нощувки в хотела?"
                        isRequired
                        isVisible={willAttend === "Да"}
                        error={error.accomodationHelp}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={accomodationHelp}
                          name="accomodationHelp"
                        >
                          <option disabled value="">
                            Моля изберете
                          </option>
                          <option value="Не, благодаря">Не, благодаря</option>
                          <option value="Да, желаем съдействие за 1 нощувка">
                            Да, желаем съдействие за 1 нощувка
                          </option>
                          <option value="Да, желаем съдействие за 2 нощувки">
                            Да, желаем съдействие за 2 нощувки
                          </option>
                        </select>
                      </ElementWrapper>

                      <ElementWrapper
                        label="Желаете ли хартиена покана за спомен?"
                        isVisible={willAttend === "Да"}
                      >
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={paperInvitation}
                          name="paperInvitation"
                        >
                          <option disabled value="">
                            Моля изберете
                          </option>
                          <option value="Да, искаме хартиена покана">
                            Да, искаме хартиена покана
                          </option>
                          <option value="Не, благодаря">Не, благодаря</option>
                        </select>
                      </ElementWrapper>
                    </div>
                    <div className="col-12 text-center">
                      <button id="submit" type="submit" className="submit">
                        Запази
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Rsvp;
