import React from "react";
import veniTeo1 from "../../images/couple/veniTeo1.jpeg";
import veniTeo2 from "../../images/couple/veniTeo2.jpeg";
import veniTeo3 from "../../images/couple/veniTeo3.jpeg";
import "./style.css";

const Kumove = () => {
  return (
    <div id="bestmen" className="couple-area section-padding pb-70">
      <div className="container">
        <div className="col-l2">
          <div className="section-title text-center">
            <h2>Кумове Михови</h2>
          </div>
        </div>
        <div className="couple-wrap">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 couple-single">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={veniTeo2} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 couple-single md-0">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={veniTeo1} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-l2">
            <div className="section-title text-center">
              <h2>Венета и Теодор</h2>
            </div>
            <div className="col-12 couple-single">
              <div className="couple-wrap2">
                <div className="couple-img3">
                  <img src={veniTeo3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kumove;
