import React, { Component } from "react";

export default class WithChildren extends Component {
  render() {
    const {
      howManyChildren,
      firstChildName,
      secondChildName,
      thirdChildName,
      error,
      changeHandler,
      isVisible,
    } = this.props;

    if (!isVisible) {
      return <></>;
    }

    return (
      <>
        <div className="col-12 col-sm-12 element-wrapper">
          <span className="checkboxtext">
            Ще дойдете ли с деца?
            <span className="text-danger">*</span>
          </span>
          <select
            className="form-control"
            onChange={changeHandler}
            value={howManyChildren}
            name="howManyChildren"
          >
            <option disabled value="-1">
              Моля изберете
            </option>
            <option value="0">Не</option>
            <option value="1">Да, с 1</option>
            <option value="2">Да, с 2</option>
            <option value="3">Да, с 3</option>
          </select>
          <p>{error.howManyChildren ? error.howManyChildren : ""}</p>
          {+howManyChildren >= 1 ? (
            <input
              type="text"
              value={firstChildName}
              onChange={changeHandler}
              placeholder="Въведете име и фамилия на детето"
              name="firstChildName"
            />
          ) : (
            false
          )}
          <p>{error.firstChildName ? error.firstChildName : ""}</p>
          {+howManyChildren >= 2 ? (
            <input
              type="text"
              value={secondChildName}
              onChange={changeHandler}
              placeholder="Въведете име и фамилия на детето"
              name="secondChildName"
            />
          ) : (
            false
          )}
          <p>{error.secondChildName ? error.secondChildName : ""}</p>
          {+howManyChildren === 3 ? (
            <input
              type="text"
              value={thirdChildName}
              onChange={changeHandler}
              placeholder="Въведете име и фамилия на детето"
              name="thirdChildName"
            />
          ) : (
            false
          )}
          <p>{error.thirdChildName ? error.thirdChildName : ""}</p>
        </div>
      </>
    );
  }
}
