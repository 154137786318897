import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import MobileMenu from "../../components/MobileMenu";

import "./style.css";

const Header = () => {
  return (
    <div className="Header_root">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-10">
              <div className="logo">
                <h2>
                <AnchorLink href="#home"> Наталия
                    <span>
                      <i className="fa fa-heart" aria-hidden="true"></i>
                    </span>
                    Лъчезар</AnchorLink>
                </h2>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="header-menu d-lg-block d-none">
                <ul className="mobail-menu d-flex">
                  <li>
                    <AnchorLink href="#couple">За нас</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#bestmen">Кумове</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#story">Нашата история</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#people">Шафери</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#event">Програма</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#rsvp">Анкета</AnchorLink>
                  </li>
                  <li>
                    <AnchorLink href="#gift">Кауза</AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-2">
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
