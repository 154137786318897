import React, { Component } from "react";

export default class AdditionalGuest extends Component {
  onChangeHandler(e) {
    this.props.changeHandler(e);
  }

  render() {
    const { willAttend, additionalGuest, error, hasAdditionalGuest } = this.props;

    if (willAttend !== "Да") {
      return <></>;
    }

    return (
      <div className="col-12 col-sm-12 element-wrapper">
        <label>
          Ще дойдете ли с половинка?
          <span className="text-danger">*</span>
        </label>
        <select
          className="form-control"
          onChange={(e) => this.onChangeHandler(e)}
          value={hasAdditionalGuest}
          name="hasAdditionalGuest"
        >
          <option disabled value="">
            Моля изберете
          </option>
          <option value="Да">Да</option>
          <option value="Не">Не</option>
        </select>
        <p>{error && error.hasAdditionalGuest ? error.hasAdditionalGuest : ""}</p>
        {hasAdditionalGuest === "Да" ? (
          <>
            <input
              type="text"
              value={additionalGuest}
              onChange={(e) => this.onChangeHandler(e)}
              placeholder="Въведете име и фамилия на вашата половинка"
              name="additiоnalGuest"
            />
            <p>{error && error.additiоnalGuest ? error.additiоnalGuest : ""}</p>
          </>
        ) : null}
      </div>
    );
  }
}
