import React, { Component } from "react";
import ElementWrapper from "./elementWrapper";

export default class ChildrenData extends Component {
    render() {
        const {
          howManyChildren,
          firstChildName,
          firstChildMenu,
          secondChildName,
          secondChildMenu,
          thirdChildName,
          thirdChildMenu,
          error,
          changeHandler,
          isVisible,
        } = this.props;
    
        if (!isVisible) {
          return <></>;
        }
    
        return (
          <>
            <ElementWrapper
              label={
                !!firstChildName
                  ? `Изберете меню за ${firstChildName}`
                  : "Изберете меню за дете 1"
              }
              isRequired
              isVisible={+howManyChildren >= 1}
              error={error.firstChildMenu}
            >
              <select
                className="form-control"
                onChange={changeHandler}
                value={firstChildMenu}
                name="firstChildMenu"
              >
                <option disabled value="">
                  Моля изберете
                </option>
                <option value="Детско Меню Хрупкави пилешки филенца, пържени картофки, краставици и млечен сос">
                  Детско Меню Хрупкави пилешки филенца, пържени картофки, краставици
                  и млечен сос
                </option>
                <option value="Меню Филе от лаврак с пюре от целина">
                  Меню Филе от лаврак с пюре от целина
                </option>
                <option value="Меню Пилешки руладини с бекон и пушено сирене">
                  Меню Пилешки руладини с бекон и пушено сирене
                </option>
              </select>
            </ElementWrapper>
    
            <ElementWrapper
              label={
                !!secondChildName
                  ? `Изберете меню за ${secondChildName}`
                  : "Изберете меню за дете 2"
              }
              isRequired
              isVisible={+howManyChildren >= 2}
              error={error.secondChildMenu}
            >
              <select
                className="form-control"
                onChange={changeHandler}
                value={secondChildMenu}
                name="secondChildMenu"
              >
                <option disabled value="">
                  Моля изберете
                </option>
                <option value="Детско Меню Хрупкави пилешки филенца, пържени картофки, краставици и млечен сос">
                  Детско Меню Хрупкави пилешки филенца, пържени картофки, краставици
                  и млечен сос
                </option>
                <option value="Меню Филе от лаврак с пюре от целина">
                  Меню Филе от лаврак с пюре от целина
                </option>
                <option value="Меню Пилешки руладини с бекон и пушено сирене">
                  Меню Пилешки руладини с бекон и пушено сирене
                </option>
              </select>
            </ElementWrapper>
    
            <ElementWrapper
              label={
                !!thirdChildName
                  ? `Изберете меню за ${thirdChildName}`
                  : "Изберете меню за дете 3"
              }
              isRequired
              isVisible={+howManyChildren >= 3}
              error={error.thirdChildMenu}
            >
              <select
                className="form-control"
                onChange={changeHandler}
                value={thirdChildMenu}
                name="thirdChildMenu"
              >
                <option disabled value="">
                  Моля изберете
                </option>
                <option value="Детско Меню Хрупкави пилешки филенца, пържени картофки, краставици и млечен сос">
                  Детско Меню Хрупкави пилешки филенца, пържени картофки, краставици
                  и млечен сос
                </option>
                <option value="Меню Филе от лаврак с пюре от целина">
                  Меню Филе от лаврак с пюре от целина
                </option>
                <option value="Меню Пилешки руладини с бекон и пушено сирене">
                  Меню Пилешки руладини с бекон и пушено сирене
                </option>
              </select>
            </ElementWrapper>
          </>
        );
      }
}
