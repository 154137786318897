import React, { Component } from "react";

export default class ElementWrapper extends Component {
  render() {
    const { label, isRequired, children, error, isVisible } = this.props;

    if (!isVisible) {
      return <></>;
    }

    return (  
      <div className="col-12 col-sm-12 element-wrapper">
        <label>
          {label}
          {isRequired ? <span className="text-danger">*</span> : false}
        </label>
        {children}
        <p>{error ? error : ""}</p>
      </div>
    );
  }
}
