import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Детайли за сватбата</h2>
                            {/* <p>Тука чичо Тео си е написал някакъв текст ей така. Лъчо е пичага, Нати е мега година, а Вени направо си я обичам. Хоноробъл меншън  Ели Томова/Арсова, Марти Арсов, Георги Даракчиев</p> */}
                            <div className="btn"><AnchorLink href='#rsvp'>Анкета</AnchorLink></div>
                            <div className="btn"><a href="https://goo.gl/maps/yeHJwokRnVTU82KL8" target='blank'>Локация</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;